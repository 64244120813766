import * as React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import MapIcon from "@material-ui/icons/Room";
import { format } from "date-fns";
import { isoToLocalDate } from "../../stores/StoreUtilities";
import OpportunityPost from "../../model/opportunity/OpportunityPost";
const styles = (theme: Theme) =>
  createStyles({
    card: {
      fontSize: 12,
      padding: "15px 20px",
    },
    status: {
      fontWeight: 200,
      color: theme.palette.text.secondary,
      maxHeight: 18,
      marginTop: theme.spacing(1),
    },
    saved: {
      textAlign: "right",
      color: theme.palette.text.secondary,
      maxHeight: 18,
    },
    icon: {
      width: 20,
      height: 20,
    },
    property: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "3px",
    },

    propIcon: {
      color: theme.palette.text.secondary,
      width: 18,
      height: 18,
      paddingTop: 2,
      marginRight: theme.spacing(1),
    },
    body: {},
    title: {
      fontWeight: 600,
      lineHeight: 1.2,
      paddingTop: 4,
      paddingBottom: 4,
    },
    account: {
      fontWeight: 600,
      // paddingTop: theme.spacing(0.5),
      // paddingBottom: theme.spacing(0.5)
    },
    summary: {
      maxHeight: 80,
      overflowY: "hidden",
    },
    hiringImmediately: {
      color: theme.palette.success.dark,
      fontWeight: 600,
    },
    background: { background: "rgb(238 236 236)", padding: "0" },
    boder_0: {
      border: 0,
      width: "100%",
    },

    paddingLeft_40: {
      color: "black",
      width: "70%",
      overflow: "hidden",
      verticalAlign: "middle",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    heading: {
      color: "midnightblue",
      fontWeight: 600,
    },
  });

class OpportunitiesFoundDetails extends React.Component<
  WithStyles<typeof styles> & any,
  { openModal: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      openModal: false,
    };
  }
  showSetAsideType = (type: string) => {
    return type ? type : 'None' 
  }
  dateFormat = (date: string) => {
    return date ? format(isoToLocalDate(date), "MM/dd/yyyy") : '' 
  };
  getValue = (value: string) => {
    return value ? value : ' ' 
  }
  showAddress = (info: OpportunityPost) => {
    let city = '';
    let state = ''
    let zip = '';
    let country = '';
    
    // city>name
    // state>code
    // zip
    // country>code

    if(info?.placeOfPerformance) {
      city = this.getValue(info?.placeOfPerformance?.city?.name);
      state = this.getValue(info && info.placeOfPerformance?.state?.code);
      country = this.getValue(info?.placeOfPerformance?.country?.code);
      zip = this.getValue(info?.placeOfPerformance?.zip);
    } 
    let address = city+' '+state+' '+zip+' '+country;
    address = address.trim();
    if(address.length === 0) {
      city = this.getValue(info?.officeAddress?.city);
      state = this.getValue(info?.officeAddress?.state);
      country = this.getValue(info?.officeAddress?.countryCode);
      zip = this.getValue(info?.officeAddress?.zipcode);
      address = city+' '+state+' '+zip+' '+country;
    }
    
    return address;
  }


  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  
  extractFileId(url: string): string | null {
    const parts = url.split('/');
    if (parts.length < 2) {
      return "Resource-Link"; // Return null if the URL format is unexpected
    }
    return parts[parts.length - 2];
  }

  render() {
    const { classes, mockData } = this.props;

    return (
      <Card className={classes.background} id="opportunityinfo_div_main" style={{ marginBottom: "15px" }}>
        <CardActionArea>
          <CardContent className={classes.card}>
            <Grid container className={classes.body}>
              <Card variant="outlined" className={classes.boder_0}>
                <CardContent className={classes.background}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.heading}
                  >
                    {mockData?.title}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Card variant="outlined" className={classes.boder_0}>
                        <CardContent className={classes.background}>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">Type</p>
                            <p className={classes.paddingLeft_40}>
                              {mockData?.type}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">Agency</p>
                            <p className={classes.paddingLeft_40}>
                              {mockData?.agency?.agency_name}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">
                              NAICS Code
                            </p>
                            <p className={classes.paddingLeft_40}>
                              {mockData?.naics?.naics_code}-
                              {mockData?.naics?.naics_title}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">
                              PSC Category
                            </p>
                            <p className={classes.paddingLeft_40}>
                              {mockData?.psc?.psccode}-{mockData?.psc?.name}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">
                              Set-Aside
                            </p>
                            <p className={classes.paddingLeft_40}>
                              {this.showSetAsideType(mockData?.typeOfSetAside)}
                            </p>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card variant="outlined" className={classes.boder_0}>
                        <CardContent className={classes.background}>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">Posted</p>
                            <p className={classes.paddingLeft_40}>
                              -
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">Updated</p>
                            <p className={classes.paddingLeft_40}>
                              {/* {this.dateFormat(mockData?.archiveDate)} */}
                              {this.dateFormat(mockData?.postedDate)}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">
                              Deadline
                            </p>
                            <p className={classes.paddingLeft_40}>
                              {" "}
                              {this.dateFormat(mockData?.responseDeadLine)}
                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            className="opportunity_outline_typo"
                          >
                            <p className="opportunity_typo_headline">
                              Award
                            </p>
                            <p className={classes.paddingLeft_40}>
                              {" "}
                              {this.dateFormat(mockData?.award?.date)}
                            </p>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid><div style={{justifyContent:"space-between",display:"flex"}}>
                  <a href={mockData?.uiLink} target="_blank" className="opportunity_sub_para" rel="noreferrer">
                    View opportunity in sam.gov
                  </a>
                  {mockData?.resourceLinks? <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.handleOpenModal}
                    >
                       Download Resource
                    </Button>:null}
                    </div>
                  <Divider
                    style={{
                      margin: "16px 0",
                      color: "#aeaeae",
                      border: "1px solid",
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}
                  >
                    <Typography style={{ display: "flex",fontSize:"14px", }}>
                      <MapIcon style={{ color: "midnightblue" }} />
                      {this.showAddress(mockData)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </CardContent>
        </CardActionArea>
         {/* Modal starts here */}
         <Dialog
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <DialogTitle id="modal-title">Resource Links </DialogTitle>
          <DialogContent style={{ width: 600 }}>
            <DialogContentText id="modal-description" >
             {mockData?.resourceLinks && mockData?.resourceLinks?.map((link: string,index:number) => {
                return <div style={{display:"flex", justifyContent:"space-between"}}>
                  <p><b>{index+1 }. </b> {this.extractFileId(link)}</p> <a href={link} target="_blank"> Download</a>
                  </div>
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Modal ends here */}
      </Card>
    );
  }
}

export default withStyles(styles)(OpportunitiesFoundDetails);
