/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeObservable, observable} from "mobx";
import * as APITypes from "../API"
import {
  AddressType,
  WorkSetting
} from "../API"
import OpportunityPost from "../model/opportunity/OpportunityPost";
import CacheStore from "./CacheStore";
import ResourceCache, { LocationOption } from "./ResourceCache";
import {getISODateToday} from "./StoreUtilities";
import {NAICS} from "../model/Industry";
import {matchSorter} from "match-sorter";
import Logger from "../components/Logger";
import ServiceRequest from "../model/ServiceRequest";

import LocationAPI from "../apis/LocationAPI";
import OpportunitiesAPI from "../apis/OpportunitiesAPI";
import OpportunityLocation from "../model/opportunity/OpportunityLocation";

export interface IOpportunitySearchOptions {
  industry?: string
  workSetting?: WorkSetting
}

export interface ServiceRequestResult {
  serviceRequests: ServiceRequest[] 
  nextToken?: string 
}

export interface GetOpportunityPost {
  opportunityPosts:OpportunityPost[]
  params?: any 
}


class OpportunityStore {
  opportunitiesAPI: OpportunitiesAPI
  resourceCache: ResourceCache
  locationAPI: LocationAPI
  jobPostStatusOptions: string[] = []
  opportunityCache?: OpportunityCacheStore
  opportunityLists: OpportunityPost[] = []

  defaultSearchRadius = 50 * 1609.34 // 50 miles in meters

  @observable isLoading = true

  constructor(options: any) {
    makeObservable(this);
    this.opportunitiesAPI = (options && options.opportunitiesAPI) ? options.opportunitiesAPI : null
    this.locationAPI = (options && options.locationAPI) ? options.locationAPI : null
    this.resourceCache = (options && options.resourceCache) ? options.resourceCache : null
    this.opportunityCache = new OpportunityCacheStore(this)
  }

  async init() {
    this.isLoading = false
  }

  getAddress = async (locationid: any) => {
    let locationInfo:any = {};
    let opportunityPosts = this.opportunityLists;
    if(opportunityPosts && opportunityPosts.length > 0) {
      if(locationid) {
        const filteredTitle = opportunityPosts.filter((item) =>
          item.locationId.includes(locationid)
        );
        if(filteredTitle && filteredTitle.length > 0) {
          let getlocationInfo = filteredTitle[0]; 
          locationInfo.id = locationid;
          locationInfo.name = getlocationInfo?.location?.Label
          locationInfo.group = getlocationInfo?.location?.Country
        }
      }
    }
    return locationInfo; 
  }

  listLocationOpportunityPostsByState = async (state: string, serviceId?: string, options: IOpportunitySearchOptions = {}) => {
    const { industry } = options
    // const key = `${serviceId ?? ''}/${industry ?? ''}`
    // const opportunityPosts = await this.opportunityCache!.get(key)

    // const locationJobPosts: LocationOpportunityPosts[] = []

    // if (opportunityPosts && opportunityPosts.length > 0) {
    //   for (const jobPost of opportunityPosts) {
    //     if (jobPost.location && jobPost.location.state === state &&
    //       (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
    //       let found = locationJobPosts.find((locationOpportunity: LocationOpportunityPosts) => locationOpportunity?.location.comparesTo(jobPost.location))
    //       if (!found) {
    //         found = new LocationOpportunityPosts(jobPost.location, [])
    //         locationJobPosts.push(found)
    //       }
    //       found.opportunityPosts.push(jobPost)
    //     }
    //   }
    // }

    // return locationJobPosts
  }

  listLocationOpportunityPostsByCountry = async (country: string, serviceId?: string, options: IOpportunitySearchOptions = {}) => {
    const { industry } = options
    // const key = `${serviceId ?? ''}/${industry ?? ''}`
    // const opportunityPosts = await this.opportunityCache!.get(key)

    // const locationJobPosts: LocationOpportunityPosts[] = []

    // if (opportunityPosts && opportunityPosts.length > 0) {
    //   for (const jobPost of opportunityPosts) {
    //     if (jobPost.location && jobPost.location.country === country  &&
    //        (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
    //       let found = locationJobPosts.find((locationOpportunity: LocationOpportunityPosts) => locationOpportunity?.location.comparesTo(jobPost.location))
    //       if (!found) {
    //         found = new LocationOpportunityPosts(jobPost.location, [])
    //         locationJobPosts.push(found)
    //       }
    //       found.opportunityPosts.push(jobPost)
    //     }
    //   }
    // }

    // return locationJobPosts
  }

  listLocationOpportunityPostsAnywhere = async (serviceId?: string, options: IOpportunitySearchOptions = {}) => {
    const { industry } = options
    // const key = `${serviceId ?? ''}/${industry ?? ''}`
    // const opportunityPosts = await this.opportunityCache!.get(key)

    // const locationJobPosts: LocationOpportunityPosts[] = []

    // if (opportunityPosts && opportunityPosts.length > 0) {
    //   for (const jobPost of opportunityPosts) {
    //     if (jobPost.location && (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
    //       let found = locationJobPosts.find((locationOpportunity: LocationOpportunityPosts) => locationOpportunity?.location.comparesTo(jobPost.location))
    //       if (!found) {
    //         found = new LocationOpportunityPosts(jobPost.location, [])
    //         locationJobPosts.push(found)
    //       }
    //       found.opportunityPosts.push(jobPost)
    //     }
    //   }
    // }

    // return locationJobPosts
  }
  
  listLocationOpportunityPostsByBounds = async (options: IOpportunitySearchOptions = {}, filter: any, splitLocationIdDigit:number) => {
    let opportunityPosts!:OpportunityPost[]; 

    if(this.opportunityLists && this.opportunityLists.length > 0 && filter.nextPage === false) {
      opportunityPosts = this.opportunityLists;
    } else {
      let apiResponse = await this.opportunityCache!.get(filter)
      if(apiResponse) {
        // console.log('rkk opportunityPosts res ::::::::', apiResponse) 
        opportunityPosts = apiResponse.opportunityPosts;
        filter = apiResponse.params;
      }
    } 
     
    if(opportunityPosts && opportunityPosts.length > 0) {
      if(filter?.title) {
        const filteredTitle = opportunityPosts.filter((item) =>
          item.title.toLowerCase().includes(filter.title.toLowerCase())
        );
        opportunityPosts = filteredTitle; 
      }
      if(filter?.type) {
        const filteredType = opportunityPosts.filter(item => item.type === filter.type);
        opportunityPosts = filteredType; 
      }
      if(filter?.agency) {
        const filteredAgency = opportunityPosts.filter((item) =>
          item.agencyCode.includes(filter.agency)
        );
        opportunityPosts = filteredAgency; 
      }
      if(filter?.naics) {
        const filteredNaics = opportunityPosts.filter((item) =>
          item.naicsCode.includes(filter.naics)
        );
        opportunityPosts = filteredNaics; 
      }
      if(filter?.set_aside_type) {
        const filteredAsideType = opportunityPosts.filter((item) =>
          item.typeOfSetAside.includes(filter.set_aside_type)
        );
        opportunityPosts = filteredAsideType; 
      }
    }
    
    let bounds: any;
    if(filter.location_type === "bounds") {
      bounds = filter.location_value;
    }

    const locationOpportunityPosts: LocationOpportunityPosts[] = []
    if (opportunityPosts && opportunityPosts.length > 0) {
      let index = 0;
      for (let opportunityPost of opportunityPosts) {
        if (opportunityPost.location && (!options.workSetting)) {
          if (bounds.contains(opportunityPost.location.lngLat)) {
            opportunityPost.index = index;
            let found = locationOpportunityPosts.find((locationOpportunity: LocationOpportunityPosts) => locationOpportunity?.location.comparesTo(opportunityPost.location, splitLocationIdDigit))
            if (!found) {
              found = new LocationOpportunityPosts(opportunityPost.location, [])
              locationOpportunityPosts.push(found)
            }
            found.opportunityPosts.push(opportunityPost)
            index = index + 1;
          }
        }
      }
    }

    let data:any = {
      params:filter, 
      locationOpportunityPosts:locationOpportunityPosts
    }
    return data;
  }
  


  // OpportunityPost methods
  listOpportunities = async (filter?: APITypes.ModelJobPostFilterInput, params?: any): Promise<GetOpportunityPost> => {
   let opportunityPosts: OpportunityPost[] = []
   
    const getLists:any = await this.opportunitiesAPI.getOpportunities(filter, params);
    if(getLists.data.LastEvaluatedKey) {
      params.Id = getLists?.data?.LastEvaluatedKey?.Id;
      params.createdAt = getLists?.data?.LastEvaluatedKey?.createdAt;
      params.naicsCode = getLists?.data?.LastEvaluatedKey?.naicsCode;
      params.nextPage = true;
    } else {
      params.Id = ''; 
      params.createdAt = ''; 
      params.naicsCode = '';
      params.nextPage = false; 
    }

    if(getLists && getLists.data && getLists.data.list) {
      opportunityPosts = getLists.data.list.map((item: any) => {
        const parts = item?.fullParentPathCode?.split('.')||''
        let agencyCode = '';
        if(parts?.length > 0) {
          agencyCode = parts[0]
        }
        item.location = item.addressLatLng;
        item.id = item.noticeId;
        item.agencyCode = agencyCode;
        

        if(item.location && item.location.Geometry && item.location.Geometry.Point && item.location.Geometry.Point.length === 2) {
          let getLocationId = this.locationAPI?.geoHashEncode(item.location.Geometry.Point[1], item.location.Geometry.Point[0]); 
          item.location.latitude = item.location.Geometry.Point[1];
          item.location.longitude = item.location.Geometry.Point[0];
          item.locationId = getLocationId;
          item.location.id = getLocationId;
        }

        if(item.typeOfSetAside == null) {
          item.typeOfSetAside = '';
        }

        const row = new OpportunityPost(item)
        this.attachLocationAndServiceDataToOpportunity(row)
        return row
      })
    }

    // this.opportunityLists = opportunityPosts
    this.opportunityLists = [...this.opportunityLists, ...opportunityPosts]

    let data:GetOpportunityPost = {
      params:params, 
      opportunityPosts:this.opportunityLists
    }
    return data;
  }
  
  attachLocationAndServiceDataToOpportunity = (opportunityPost: OpportunityPost) => {
    if (opportunityPost) {
      let agencyInfo = this.resourceCache!.getAgencyOption(opportunityPost.agencyCode)
      if(agencyInfo && agencyInfo.agency_name) {
        opportunityPost.agency = agencyInfo; 
      }
    }
  }

}

export default OpportunityStore





export class LocationOpportunityPosts {
  location: OpportunityLocation
  opportunityPosts: OpportunityPost[]

  constructor (location: OpportunityLocation, opportunityPosts: OpportunityPost[]) {
    this.location = location
    this.opportunityPosts = opportunityPosts
  }
}



const CACHE_EXPIRE_SECS = 1

// This caches OpportunityPost searches based on serviceId/industry
class OpportunityCacheStore extends CacheStore<GetOpportunityPost> {

  constructor (OpportunityStore: OpportunityStore) {
    super(CACHE_EXPIRE_SECS, async (params:any): Promise<GetOpportunityPost | undefined> => {
      // At some point we may want to support a key that contains the locationId and/or serviceId.
      // let opportunityPosts:any = []

      const todaysISODate = getISODateToday()
      let filter: APITypes.ModelJobPostFilterInput = {
        and: [
          {openDate: {le: todaysISODate}},
          { or: [
            {closedDate: {attributeExists: false}},
            {closedDate: {ge: todaysISODate}}
          ]}
        ]
      }

      let response = await OpportunityStore.listOpportunities(filter, params)
      return response;
    })
  }

  removeKey = (params:any) => {
    this.remove(params)
  }
}